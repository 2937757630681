export const HOST = 'http://stg.zastlogisolutions.com';

export const REQUEST_TYPE = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};

export const PATH = {
  ROOT: '/',
  SIGN_IN: '/sign-in',
  // DASHBOARD: '/dashboard',
  FORGOT_PASSWORD: '/forgot-password',

  SHAREABLE_TRACKING_LINK: '/ui/track/:tripId',

  TRIPS: {
    ROOT: '/trips',
    ESCALATED: '/trips/escalated',
    NEW: '/trips/new',
    IN_TRANSIT: '/trips/in-transit',
    IN_TRANSIT_ESCALATED: '/trips/is_escalated/transits',
    AT_DESTINATION: '/trips/at-destination',
    AT_DESTINATION_ESCALATED: '/trips/escalated/at-destination/is_escalated',
    CREATE_LR: '/trips/create-lr/:tripId',
    VIEW_ALL_LR: '/trips/view-lr/:tripId',
    VIEW_LR: '/trips/view-lr/:tripId/:lrId',
    PRINT_LR: '/trips/print-lr/:tripId/:lrId',
    UNVERIFIED_DRY_RUNS: '/trips/unverified-dry-runs',
    UNVERIFIED_DRY_RUNS_TRIP_REPORT: '/trips/unverified-dry-runs-trip-report',
    VEHICLE_STATUS_SUMMARY_REPORT: '/trips/vehicle-status-summary-report',
    VEHICLE_STATUS_SUMMARY_REPORT_DETAIL:
      '/trips/vehicle-status-summary-report-detail',
    // ticket management system
    TMS: '/trips/tickets',
    CLOSED_TMS: '/trips/closed-tickets',
  },
  BOOKINGS: {
    ROOT: '/booking',
    NEW: '/booking/new',
    ALL_BOOKING: '/booking/all-booking',
    REJECTED: '/booking/rejected',
    BOOKING_WITHOUT_DRIVER: '/booking/without-driver',
    SCHEDULED_BOOKING: '/booking/scheduled',
    ACCEPTED: '/booking/accepted',
    ASSIGNED: '/booking/assigned',
    DOCKED: '/booking/docked',
    CREATE: {
      ROOT: '/booking/create-booking',
      AD_HOC_ONE_WAY: '/booking/create-booking',
      AD_HOC_TWO_WAY: '/booking/create-booking',
    },
    EDIT: '/booking/edit/:bookingId',
    ACCEPT: '/booking/accept-booking/:bookingId',
    AVAILABLE_VEHICLES: '/booking/available-vehicles',
    AVAILABLE_VEHICLES_REPORT: '/booking/available-vehicles/report',
  },
  DO: {
    ROOT: '/do',
    CREATE: '/do/create',
    EDIT_DO: '/do/edit',
    EDIT: '/do/manage/:doId',
    LR_STEPS: '/do/lr-steps/:tripData',
  },
  TRACKING: {
    ROOT: '/tracking',
    ALL: '/tracking/track-all',
    ENGAGED_ZAST: '/tracking/recent',
    GPS_ON: '/tracking/gps-on',
    GPS_OFF: '/tracking/gps-off',
    TRIP_CODE: '/tracking/trip-code',
    MAP_VIEW: '/tracking/map-view',
    DRIVER_TRACKING: '/tracking/driver-tracking',
    DRIVER_UPLOADS: '/tracking/driver-uploads',
    DRIVER_BIN: '/tracking/bin',
    GEO_FENCE: {
      ROOT: '/tracking/geo-fence',
      ALL: '/tracking/geo-fence',
      ADD: '/tracking/geo-fence/add',
      EDIT: '/tracking/geo-fence/edit/:fenceId',
    },
  },
  RECORDS: {
    ROOT: '/record',
    VENDORS: {
      ROOT: '/record/vendor',
      ALL: '/record/vendor/all',
      ADD: '/record/vendor/add',
      EDIT: '/record/vendor/edit/:vendorId',
      FINANCE_DETAILS: '/record/vendor/finance',
      DRIVERS: '/record/vendor/all-drivers',
      ADD_DRIVER: '/record/vendor/add-driver',
    },
    VEHICLE: {
      ROOT: '/record/vehicle',
      ALL: '/record/vehicle/all',
      ADD: '/record/vehicle/add',
      EDIT: '/record/vehicle/edit/:vehicleId',
    },
    CUSTOMER: {
      ROOT: '/record/customer',
      ALL: '/record/customer/all',
      FINANCE_DETAILS: '/record/customer/finance',
      ADD: '/record/customer/add',
      EDIT: '/record/customer/edit/:customerId',
    },
    DRIVER: {
      ROOT: '/record/driver',
      ZAST: '/record/driver/zast',
      OTHER: '/record/driver/other',
      ADD: '/record/driver/add',
      EDIT: '/record/driver/edit/:driverId',
    },
    OTHERS: {
      ROOT: '/record/others',
      ROUTE: '/record/others/route-tat',
      ROUTE_ADD: '/record/others/route-tat/add',
      CITIES: '/record/others/cities',
      CITIES_ADD: '/record/others/cities/add',
      CONSIGNOR: '/record/others/consignor',
      CONSIGNOR_ADD: '/record/others/consignor/add',
      DISTANCE_MAP: '/record/others/distance-map',
    },
    USERS: {
      ROOT: '/record/users',
      ALL: '/record/users',
      ADD: '/record/users/add',
      EDIT: '/record/users/edit/:userId',
    },
    CUSTOMER_MIC: '/record/mis',
    BILLING_ENTITY: '/record/billing-entity',
    PAYMENT_MAPPING: '/record/payment-entity',
  },
  POD: {
    ROOT: '/pod',
    BULK_UPLOAD_POD: '/pod/bulk-upload-pod',
    STATUS_UPDATE: '/pod/pod-status-update',
    POD_REPORT: {
      ROOT: '/pod/pod-report',
      SUMMARY_REPORT: '/pod/pod-report/summary-report',
      DETAILED_REPORT: '/pod/pod-report/detailed-report',
    },
  },
  UPLOAD: {
    ROOT: '/upload',
    BULK_UPLOAD: '/upload/bulk-upload',
    LATEST_UPLOADS: '/upload/latest-uploads',
  },
  REVENUE: {
    ROOT: '/revenue',
    MARKET_REVENUE: {
      TRIPS_REVENUE_MISSING: '/revenue/market-revenue/missing',
      TRIPS_REVENUE: '/revenue/market-revenue/added',
      CUSTOMER: '/revenue/market-revenue/missing/customer/:customerId',
    },
    RECEIVABLES: {
      RECEIVABLES_MAIN: '/revenue/receivables',
      RECEIVABLES_DETAILS: '/revenue/receivables/details/:id',
    },
    UNBILLED_TRIPS: {
      MAIN: '/revenue/unbilled',
    },
    BASE_RATE: {
      ROOT: '/revenue/base-rate',
      ADD: '/revenue/base-rate/add',
      ALL: '/revenue/base-rate/all',
    },
    REVENUE_TRIPS: {
      ROOT: '/revenue/rev-search-trips',
      SEARCH: '/revenue/rev-search-trips',
      VIEW_ANNEXURE: '/revenue/rev-search-trips/annexure/:customerId',
    },
    REVENUE_COST_TRIPS: {
      ROOT: '/revenue/cost-search-trips',
      SEARCH: '/revenue/cost-search-trips',
      VIEW_ANNEXURE: '/revenue/cost-search-trips/annexure/:customerId',
    },
    ANNEXURES: {
      ROOT: '/revenue/annexures',
      CREDIT_NOTE: '/revenue/annexures/credit-note/:annexureId',
      SUB_ANNEXURE: '/revenue/annexures/new-annexure/:annexureId',
      VIEW_EDIT_ANNEXURE: '/revenue/annexures/view-edit/:annexureId',
    },
    SEND_TO_TALLY: {
      ROOT: '/revenue/send-to-tally',
    },
    RE_SEND_TO_TALLY: {
      ROOT: '/revenue/resend-to-tally',
    },
    RATE_MATRIX: {
      ROOT: '/revenue/rate-matrix',
      ROUTE_MARGIN: '/revenue/rate-matrix/route-margin',
      COST: '/revenue/rate-matrix/cost',
      FORWARD_ROUTE_RATE: '/revenue/rate-matrix/forward-route-rate',
      RETURN_ROUTE_RATE: '/revenue/rate-matrix/return-route-rate',
      KAM_REVENUE: '/revenue/kam-revenue',
      FREIGHT: '/revenue/kam/freight',
      EXCEPTIONS: '/revenue/kam/exceptions',
      EXCEPTIONS_TABLE: '/revenue/kam/details/exceptions/:id',
      FREIGHT_TABLE: '/revenue/kam/details/freights/:id',
      OTHER: '/revenue/kam/other',
      OTHER_TABLE: '/revenue/kam/details/others/:id',
      APPROVALS: '/revenue/kam/approvals',
      APPROVALS_TABLE: '/revenue/kam/details/approvals/:id',
      APPROVALS_COST_TABLE: '/cost/details/approvals/:id',
    },
  },
  ADMIN_PANEL: {
    ROOT: '/alert-mail-dashboard',
    ALERT_MAIL_DASHBOARD: '/alert-mail-dashboard/roles',
    ROLE_EDIT: '/alert-mail-dashboard/roles/edit/:roleId',
  },
  COST: {
    ROOT: '/cost',
    TRIP_COST: {
      FREIGHT_COST: '/cost/freight',
      FREIGHT_COST_TABLE: '/cost/details/freights/:id',
      OTHER_COST: '/cost/other',
      OTHER_COST_TABLE: '/cost/details/others/:id',
      EXCEPTIONS_COST: '/cost/exceptions',
      EXCEPTIONS_TABLE_COST: '/cost/details/exceptions/:id',
      APPROVALS_COST: '/cost/approvals',
      APPROVALS_COST_TABLE: '/cost/details/approvals/:id',
    },
    VENDOR_TRIPS: {
      ROOT: '/cost/vendor-search-trips',
      SEARCH: '/cost/vendor-search-trips',
    },
    UPDATE_VENDOR_TRIPS: {
      ROOT: '/cost/vendor-search-trips',
      SEARCH: '/cost/vendor-search-trips',
      VIEW_ANNEXURE: '/cost/cost-search-trips/annexure/:customerId',
    },
    COST_ANNEXURES: {
      ROOT: '/cost/annexures',
      DEBIT_NOTE: '/cost/annexures/debit-note/:annexureId',
      SUB_ANNEXURE: '/cost/annexures/new-annexure/:annexureId',
      VIEW_EDIT_ANNEXURE: '/cost/annexures/view-edit/:annexureId',
      DISPUTE: '/cost/annexures/dispute',
      RESOLVE_DISPUTE: '/cost/annexures/resolve/dispute/:id',
      APPROVE_REJECT_DISPUTE: '/cost/annexures/approve/reject/dispute/:id',
      DISPUTED_ANNEXURE: '/cost/annexures/disputed/:disputeId',
      RAISE_DISPUTE: '/cost/annexures/raise-dispute/:annexureId',
      OWN_BILLING_ANNEXURE: '/cost/own-billing-annexures',
    },
    VENDOR_BASE_RATE: {
      ROOT: '/cost/vendor-base-rate',
      ADD: '/cost/vendor-base-rate/add',
      ALL: '/cost/vendor-base-rate/all',
      EDIT: '/cost/vendor-base-rate/edit/:baseRateId',
    },
  },
  REPORT: {
    ROOT: '/report',
    VEHICLE: '/report/vehicle',
    VEHICLE_RUN_REPORT: '/report/average-run-report',
    VEHICLE_RESULT: '/report/trips/:vehicleId',
    DOWNLOAD_REPORT: '/report/download-report',
    CUSTOMER_TRIP_COUNT: '/report/customer-trip-count',
    DAILY_CUSTOMER_REPORT: '/report/daily-customer-report',
    DAILY_CUSTOMER_REPORT_RESULT: '/report/daily-customer-trip-report',
    VENDOR_WISE_DISTRIBUTION: '/report/vendor-wise-distribution',
    ZONE_WISE_DISTRIBUTION: '/report/zone-wise-distribution',
    ZAST_NOW: '/report/zast-now',
    PAYMENT: '/report/payment-report',
    CONTRACTED_VEHICLE_REPORT: '/report/contracted-vehicle-report',
    CUSTOMER_REPORT: '/report/customer-report',
    VEHICLE_STATUS_SUMMARY_REPORT_DETAIL:
      '/report/vehicle-status-summary-report-detail',
    VEHICLE_STATUS_SUMMARY_REPORT: '/report/vehicle-status-summary-report',
    VEHICLE_UTILIZATION: '/report/vehicle-utilization',
  },
  OWN_VEHICLES: {
    ROOT: '/own-vehicles',
    ATTENDANCE: '/own-vehicles/attendance',
    FAST_TAG: '/own-vehicles/upload/fast-tag',
    DIESEL: '/own-vehicles/upload/diesel',
    APPROVE_FAST_TAG: '/own-vehicles/approve/fast-tag',
    APPROVE_DIESEL: '/own-vehicles/approve/diesel',
    REPAIR_MAINTENANCE: '/own-vehicles/repair-and-maintenance',
    HAPPAY_PROCESSING_REQUEST: '/own-vehicles/happay-processing-request',
  },
  DIGITAL_FORMS: {
    ROOT: '/digital-forms',
    LR: '/digital-forms/LR',
  },
  FNF: {
    ROOT: '/fnf',
    DRIVER: '/fnf/driver/:driverId',
    FNF_SETTLEMENT: '/fnf/settlement-form/:tripId',
  },
  PERFORMANCE: {
    ROOT: '/performance',
    TRIP: '/performance/trip',
    TRIP_RESULT: '/performance/trip/result/:vehicleId',
    CUSTOMER: '/performance/vendor',
  },
  FINANCE: {
    ROOT: '/finance',
    TRIP_ADVANCE: '/finance/trip-advance',
    ADVANCE_REQUEST: '/finance/view-requested-advance',
    UPLOAD: '/finance/upload',
    HAPPAY_CARD: '/finance/happay-card-history',
    PAYMENT_MAPPING: '/finance/payment-mapping',
    PENDING_REQUEST: '/finance/pending-request',
    PROCESSED_REQUEST: '/finance/processed-request',
    PROCESS_ADVANCE_REQUEST_ID: '/finance/utr/edit/:id',

    PENDING_TRIPS: '/finance/pending-trips',
    PENDING_TRIPS_NOT_RECEIVED: '/finance/pending-trips/not-received/:id',
    PENDING_TRIPS_RECEIVED: '/finance/pending-trips/received/:id',
    ADD_NEW_ADVANCE: '/finance/add-new',

    TRIP_PAYOUT: '/finance/trip-payout',
    ACP_HISTORY: '/finance/trip-payout-history',

    DIRECT_PAYOUT: '/finance/direct-payout',
    ACP_DIRECT_PAYOUT_HISTORY: '/finance/direct-payout-history',

    CUSTOMER: '/finance/pending-trips/customer/:id',

    PAYMENT_MAPPING_DETAILS: '/finance/payment-mapping/details/:id',
  },
  SEARCH: '/search',
  ALERT_MAIL: '/alert-mail',
  LOGOUT: '/sign-out',
};

export const MODALS = {
  TRIP_TYPE_CHANGE: 'TRIP_TYPE_CHANGE',
  TICKET_DETAILS: 'TICKET_DETAILS',
  RAISE_TRIP_TICKET: 'RAISE_TRIP_TICKET',
  TICKET_STATUS_CHANGE: 'TICKET_STATUS_CHANGE',
  UPDATE_TICKET: 'UPDATE_TICKET',
  EDIT_TRIP_ROUTE: 'EDIT_TRIP_ROUTE',
  COPY_DETAILS: 'COPY_DETAILS',
  ADD_COMMENT: 'ADD_COMMENT',
  ADD_BOOKING_COMMENT: 'ADD_BOOKING_COMMENT',
  TRIP_DETAILS: 'TRIP_DETAILS',
  ADVANCE_TRIP_DETAILS: 'ADVANCE_TRIP_DETAILS',
  PARTNER_DETAILS: 'PARTNER_DETAILS',
  EDIT_TRIP_VEHICLE: 'EDIT_TRIP_VEHICLE',
  EDIT_REVENUE_BASE_RATE: 'EDIT_REVENUE_BASE_RATE',
  ADD_REVENUE_BASE_RATE: 'ADD_REVENUE_BASE_RATE',
  EDIT_REVENUE_DETAILS: 'EDIT_REVENUE_DETAILS',
  EDIT_VENDOR_COST_DETAILS: 'EDIT_VENDOR_COST_DETAILS',
  PRINT_ANNEXURE: 'PRINT_ANNEXURE',
  OTHERS_HEADER_EDIT: 'OTHERS_HEADER_EDIT',
  CUSTOMER_DETAILS: 'CUSTOMER_DETAILS',
  VENDOR_DETAILS: 'VENDOR_DETAILS',
  CROSSDOCK_VEHICLE: 'CROSSDOCK_VEHICLE',
  MARK_TRIP_AS_BEGIN: 'MARK_TRIP_AS_BEGIN',
  MARK_TRIP_AS_REACHED: 'MARK_TRIP_AS_REACHED',
  MARK_TRIP_AS_CLOSED: 'MARK_TRIP_AS_CLOSED',
  MARK_TRIP_AS_HALTED: 'MARK_TRIP_AS_HALTED',
  TERMINATE_TRIP: 'TERMINATE_TRIP',
  SEARCH: 'SEARCH',
  TRACK_LOCATION: 'TRACK_LOCATION',
  ADD_LOCATION: 'ADD_LOCATION',
  CHART_VIEW: 'CHART_VIEW',
  EDIT_DRIVER_PHONE: 'EDIT_DRIVER_PHONE',
  PLOT_ROUTE: 'PLOT_ROUTE',
  TOUCHING_IN: 'TOUCHING_IN',
  TOUCHING_OUT: 'TOUCHING_OUT',
  FILTERS: 'FILTERS',
  UPDATE_MULTIPLE_LOCATIONS: 'UPDATE_MULTIPLE_LOCATIONS',
  TRACKING_DETAILS: 'TRACKING_DETAILS',
  TRACKING_FILTER: 'TRACKING_FILTER',
  TRACKING_UPLOAD_FILTER: 'TRACKING_UPLOAD_FILTER',
  EDIT_DATE_TIME: 'EDIT_DATE_TIME',
  BOOKING_DETAILS: 'BOOKING_DETAILS',
  BASE_RATE_APPLY_DETAILS: 'BASE_RATE_APPLY_DETAILS',
  REVIVE_BOOKING: 'REVIVE_BOOKING',
  DEACTIVATE_BOOKING: 'DEACTIVATE_BOOKING',
  REJECT_BOOKING: 'REJECT_BOOKING',
  SEND_TO_LOAD_BOARD: 'SEND_TO_LOAD_BOARD',
  ASSIGN_BOOKING: 'ASSIGN_BOOKING',
  REASSIGN_BOOKING: 'REASSIGN_BOOKING',
  DOCK_BOOKING: 'DOCK_BOOKING',
  BOOKING_FILTER: 'BOOKING_FILTER',
  BOOKING_CREATED: 'BOOKING_CREATED',
  UPLOAD_DOCUMENT: 'UPLOAD_DOCUMENT',
  SUCCESS_MODAL: 'SUCCESS_MODAL',
  VERIFY_VENDOR: 'VERIFY_VENDOR',
  VERIFY_CUSTOMER: 'VERIFY_CUSTOMER',
  WARNING_MESSAGE: 'WARNING_MESSAGE',
  DOCUMENT_VIEWER: 'DOCUMENT_VIEWER',
  FILTER_RECORDS: 'FILTER_RECORDS',
  FILTER_DO: 'FILTER_DO',
  FILTER_MAP_VIEW: 'FILTER_MAP_VIEW',
  EDIT_DISTANCE: 'EDIT_DISTANCE',
  DELETE_GEO_FENCE: 'DELETE_GEO_FENCE',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  MARK_INACTIVE_VEHICLE: 'MARK_INACTIVE_VEHICLE',
  ACTIVATE_BOOKING: 'ACTIVATE_BOOKING',
  TRIPS_FILTER: 'TRIPS_FILTER',
  REJECT_VENDOR_CUSTOMER: 'REJECT_VENDOR_CUSTOMER',
  UPLOAD_SUCCESS_MODAL: 'UPLOAD_SUCCESS_MODAL',
  SUMMARY_REPORT_FILTER: 'SUMMARY_REPORT_FILTER',
  DETAILED_REPORT_FILTER: 'DETAILED_REPORT_FILTER',
  UPLOAD_DOCUMENT_FOR_POD: 'UPLOAD_DOCUMENT_FOR_POD',
  POD_HISTORY: 'POD_HISTORY',
  ADD_TRIP_SUPPORT_DOC: 'ADD_TRIP_SUPPORT_DOC',
  VIEW_TRIP_SUPPORT_DOC: 'VIEW_TRIP_SUPPORT_DOC',
  VIEW_REVENUE_TRIPS_SUPPORT_DOC: 'VIEW_REVENUE_TRIPS_SUPPORT_DOC',
  EDIT_VENDOR_MODAL: 'EDIT_VENDOR_MODAL',
  EDIT_TAT_MODAL: 'EDIT_TAT_MODAL',
  APPROVE_ATTENDANCE: 'APPROVE_ATTENDANCE',
  FILTER_FANDF: 'FILTER_FANDF',
  FILTER_OWN_VEHICLES: 'FILTER_OWN_VEHICLES',
  FILTER_REVENUE: 'FILTER_REVENUE',
  FILTER_COST: 'FILTER_COST',
  APPROVE_FAST_TAG: 'APPROVE_FAST_TAG',
  APPROVE_DIESEL: 'APPROVE_DIESEL',
  BEGIN_TRIP_WITHOUT_CONSENT: 'BEGIN_TRIP_WITHOUT_CONSENT',
  FETCH_DRIVER_CONSENT: 'FETCH_DRIVER_CONSENT',
  HALT_DURATIONS: 'HALT_DURATIONS',
  UPDATE_TRIP_CHARGES: 'UPDATE_TRIP_CHARGES',
  IS_DEPLOYER_SAME: 'IS_DEPLOYER_SAME',
  OTP_VERIFIED: 'OTP_VERIFIED',
  ADJUST_ADVANCE: 'ADJUST_ADVANCE',
  DELETE_REQUEST_STATUS: 'DELETE_REQUEST_STATUS',
  APPROVE_REQUEST_STATUS: 'APPROVE_REQUEST_STATUS',
  REJECT_REQUEST_STATUS: 'REJECT_REQUEST_STATUS',
  TRIP_ADVANCE_HISTORY: 'TRIP_ADVANCE_HISTORY',
  TRIP_CHARGES: 'TRIP_CHARGES',
  PREV_TRIP_CHARGES: 'PREV_TRIP_CHARGES',
  TRIP_CHARGES_ADDED: 'TRIP_CHARGES_ADDED',
  CONFIRM_BOOKING: 'CONFIRM_BOOKING',
  DOWNLOAD_CONTRACTED_VEHICLE_REPORT: 'DOWNLOAD_CONTRACTED_VEHICLE_REPORT',
  VERIFY_DRY_RUN: 'VERIFY_DRY_RUN',
  DOWNLOAD_DRIVER_ATTENDANCE: 'DOWNLOAD_DRIVER_ATTENDANCE',
  REVENUE_SUBMIT_BILLING: 'REVENUE_SUBMIT_BILLING',
  EDIT_TRIP_REFERENCE: 'EDIT_TRIP_REFERENCE',
  EDIT_DISTANCE_REVENUE: 'EDIT_DISTANCE_REVENUE',
  EDIT_TRIP_REVENUE_REFERENCE: 'EDIT_TRIP_REVENUE_REFERENCE',
  COMMENT_ANNEXURE: 'COMMENT_ANNEXURE',
  SAVE_BILL_DETAILS: 'SAVE_BILL_DETAILS',
  ANNEX_TRIP_REJECT: 'ANNEX_TRIP_REJECT',
  ANNEXURE_UPLOAD_BILL: 'ANNEXURE_UPLOAD_BILL',
  UPDATE_DIESEL_RATE: 'UPDATE_DIESEL_RATE',
  VENDOR_COST_UPDATE_DIESEL_RATE: 'VENDOR_COST_UPDATE_DIESEL_RATE',
  VEHICLE_TYPE_REVENUE: 'VEHICLE_TYPE_REVENUE',
  DETENTION_DAYS: 'DETENTION_DAYS',
  VEHICLE_DOCUMENT_HISTORY: 'VEHICLE_DOCUMENT_HISTORY',
  TRIP_BEGIN_STEP_FORM: 'TRIP_BEGIN_STEP_FORM',
  RATE_MATRIX_UPLOAD: 'RATE_MATRIX_UPLOAD',
  BULK_UPLOAD_DOCUMENT: 'BULK_UPLOAD_DOCUMENT',
  BULK_UPLOAD_FAILURE: 'BULK_UPLOAD_FAILURE',
  PENDING_TRIPS_FOR_BILLING: 'PENDING_TRIPS_FOR_BILLING',
  RELEASE_LOADS: 'RELEASE_LOADS',
  ASSIGN_VEHICLE_MODAL: 'ASSIGN_VEHICLE_MODAL',
  ADD_BENEFICIARY_MODAL: 'ADD_BENEFICIARY_MODAL',
  IMAGE_VIEWER_WITH_DETAILS: 'IMAGE_VIEWER_WITH_DETAILS',
};

export const MODAL_TYPE = {
  CREATED: 'CREATED',
  UPDATED: 'UPDATED',
};

export const STATES = {
  VENDOR_ID: 'vendorId',
  VEHICLE_ID: 'vehicleId',
  ENGAGED_BY: 'engagedById',
  CITY_ID: 'cityId',
  CROSSDOCK_REASON: 'crossDockReasonId',
  HALT_REASON_ID: 'haltId',
  HALT_REASON_OTHERS_VALUE: '73',
  TERMINATE_REASON_ID: 'terminateId',
  VEHCILE_TYPE_ID: 'vehicleTypeId',
  ROUTE_ID: 'routeId',
  CUSTOMER_ID: 'customer_id',
  CLUSTER_ID: 'cluster_id',
  DELAY_REASONS: 'delayId',
  REJECT_REASON: 'rejectId',
  VEHICLE_CATEGORY_ID: 'vehicle_category',
  DRIVER_STATUS: 'driver_status',
  ZONES: 'zoneId',
  STATE: 'stateId',
  CONSIGNOR: 'consignorId',
  VENDOR_ADVANCE_TYPE: 'advance_type',
  VENDOR_BILLING_TYPES: 'billing_type',
  ZAST_VEHICLE: 'zast_vehicle',
};

export const VEHICLE_CATEGORY = {
  ZAS: 'ZAS',
  CON: 'CON',
};

export const DATE_MODE = {
  DATE: 'DATE',
  DATE_TIME: 'DATE_TIME',
};

export const TRIP_DETAILS_URLS = {
  TRACKING_HISTORY: '/trip/tracking-history/',
  QUESTIONNAIRE: '/trip/questionnaire/',
  RUNNING_STATUS: '/trip/running-status/',
  TRIP_LIFECYCLE: '/trip/trip-history/',
  DRIVER_DETAILS: '/trip/get-driver-details/',
  COMMENTS: '/trip/trip-comment/',
  TRIP_EWAY_DATA: '/trip/get-eway-data/',
};

export const BOOKING_DETAILS_URL = {
  BOOKING_LIFECYCLE: 'history',
  TRACKING_HISTORY: 'tracking-history',
  COMMENTS: '/booking/booking-comment/',
};

export const DOWNLOAD_URLS = {
  NEW: 'created',
  IN_TRANSIT: 'all-in-transit',
  AT_DESTINATION: 'arrived-at-destination',
  NEW_ESCALATED: 'created?is_escalated=true',
  IN_TRANSIT_ESCALATED: 'all-in-transit?is_escalated=true',
  AT_DESTINATION_ESCALATED: 'arrived-at-destination?is_escalated=true',
};

export const TABS = {
  TRACKING_HISTORY: 'Tracking History',
  COMMENTS: 'Comments',
  RUNNING_STATUS: 'Running Status',
  TRIP_LIFECYCLE: 'Trip Lifecycle',
  DRIVER_DETAILS: 'Driver Details',
  QUESTIONNAIRE: 'Questionnaire',
};

export const RECORD_TYPE = {
  VEHICLES: '/vehicle/all',
  DRIVERS: '/vendor/all-drivers',
  ROUTE_TAT: '/booking/route-tat',
  CITIES: '/booking/all-cities',
};

export const TRIP_STATUS = {
  NEW: 'created',
  escalated: 'created?is_escalated=true',
  IN_TRANSIT_ESCALATED: 'all-in-transit?is_escalated=true',
  IN_TRANSIT: 'all-in-transit',
  AT_DESTINATION_ESCALATED: 'arrived-at-destination?is_escalated=true',
  AT_DESTINATION: 'arrived-at-destination',
};

export const BOOKING_STATUS = {
  SCHEDULED: 'scheduled',
  REJECTED: 'rejected',
  BOOKING_WITHOUT_DRIVER: 'without-driver',
  NEW_REQUESTS: 'requested',
  ACCEPTED: 'accepted',
  ASSIGNED: 'assigned',
  DOCKED: 'docked',
};

export const USER_RIGHTS = {
  ALL_TRIPS_TOGGLE_REVENUE_CAPTURING: 'all-trips-toggle-revenue-capturing',
  DELTA_LR_CREATION: 'delta-lr-creation',
  ADD_FALCON_SHORTAGE: 'add-falcon-shortage',
  REJECT_APPROVED_ANNEXURE: 'reject-approved-annexure',
  VIEW_FALCON_DAILY_CUSTOMER_REPORT: 'view-falcon-daily-customer-report',
  VIEW_FALCON_CUSTOMER_TRIP_REPORT: 'view-falcon-customer-trip-report',
  VIEW_ALERT_MAIL_DASHBOARD: 'alert-mail-dashboard',
  VIEW_DO: 'view-do',
  SUPPLY_RATE_VISIBILITY: 'supply-rates-visibility',
  CREATE_DO: 'create-do',
  CLOSE_DO: 'close-do',
  CREATE_MANUAL_TICKET: 'create-manual-ticket',
  VIEW_TMS: 'view-ticket-management-system',
  VIEW_RECEIVABLES: 'view-receivables-report',
  UNBILLED_TRIPS: 'unbilled-trips-report',
  EDIT_OTHER_CHARGES: 'edit-other-charges',
  ASSIGN_ZAST_NOW_VEHICLE: 'assign-zast-now-vehicle',
  UPLOAD_SOFT_COPY_PERMISSION: 'upload-soft-copy-pod',
  APPROVE_REJECT_TRIPS_DOCS: 'approve-reject-tripsupportingdocs',
  UPLOAD_HARD_COPY_PERMISSION: 'upload-hard-copy-pod',
  POD_LOST_NOT_REQUIRED: 'pod-lost-not-required',
  POD_NOT_REQUIRED_HOLD_FNF: 'pod-not-required-hold-fnf',
  DOWNLOAD_VENDOR_BASE_RATE: 'download-vendor-base-rate',
  VIEW_TALLY_ANNEXURES: 'tally-annexure',
  VIEW_RE_SEND_TO_TALLY: 'resend-to-tally-annexure',
  ADD_VENDOR_BASE_RATE: 'add-vendor-base-rate',
  VIEW_VENDOR_BASE_RATE: 'view-vendor-base-rate',
  VIEW_ZAST_NOW_ANNEXURE: 'view-zast-now-annexures',
  VIEW_REVENUE_BASE_RATE: 'view-revenue-base-rate',
  ADD_REVENUE_BASE_RATE: 'add-revenue-base-rate',
  CUSTOMER_BILLING: 'customer-billing',
  VENDOR_BILLING: 'vendor-billing',
  CREATE_BOOKING: 'create-booking',
  CREATE_ADHOC_ONE_WAY_BOOKING: 'create-adhoc-one-way-booking',
  ACCEPT_BOOKING: 'accept-booking',
  ACCEPT_ASSIGN_BOOKING_PD_USER: 'allow-pd-user-booking-actions',
  DELETE_TRIP_SUPPORTING_DOCS: 'delete-tripsupportingdocs',
  ASSIGN_BOOKING: 'assign-booking',
  EDIT_BOOKING: 'edit-booking',
  EDIT_CATEGORY: 'edit-booking-category',
  EDIT_SCHEDULED_BOOKING: 'edit-scheduled-booking',
  EDIT_TAT: 'edit-tat',
  REVENUE_APPROVAL: 'show-trip-revenue-approval',
  ACCEPT_REJECT_DESPUTE: 'approve-cost-annexure',
  FREIGHT_OTHERS: 'show-trip-revenue-freight-others',
  ALL_KAM: 'show-trip-revenue-page',
  ALL_COST: 'show-trip-charges-page',
  VIEW_COST_APPROVALS: 'show-trip-charges-approval',
  VIEW_COST_FREIGHT: 'show-trip-charges-freight-others',
  VIEW_EXCEPTIOS: 'show-trip-charges-exceptions',
  DOCK_BOOKING: 'dock-booking',
  REJECT_BOOKING: 'reject-booking',
  SEND_TO_LOAD_BOARD: 'send-to-load-board',
  SCHEDULED_BOOKING_ACTIVATION: 'scheduled-booking-activation',
  DOWNLOAD_BOOKING: 'download-booking',
  EDIT_FROM_SEARCH: 'edit-from-search',
  // EDIT_REFERENCE: 'edit-reference',
  REVIVE_BOOKING: 'revive-booking',
  VIEW_USER: 'view-user',
  DOWNLOAD_EXCEL: 'download-user',
  ADD_USER: 'add-user',
  EDIT_USER: 'edit-user',
  CHANGE_PASSWORD: 'change-password',
  VIEW_USER_LOGS: 'view-user-logs',
  CHANGE_OWN_PASSWORD: 'change-own-password',
  SET_CUSTOMERS: 'set-customers',
  // ADD_FACILITY_ADDRESS: 'add-facility-address',
  MODIFY_CITY: 'modify-city',
  CUSTOMER_MIS_MAIL: 'customer-mis-mail',
  VIEW_BILLING_ENTITY: 'view-billing-entity',
  ACTION_BILLING_ENTITY: 'action-billing-entity',
  VIEW_PAYMENT: 'view-payment-entity',
  MODIFY_CONSIGNORS: 'modify-consignors',
  UPDATE_ROUTE_TAT: 'update-route-tat',
  MODIFY_DISTANCE_MAP: 'modify-distance-map',
  EDIT_MASTER_DISTANCE: 'edit-master-distance',
  ADD_VENDOR: 'add-vendor',
  EDIT_VENDOR: 'edit-vendor',
  VENDOR_BLACK_LIST: 'vendor-blacklist',
  ADD_VENDOR_FINANCE_DETAILS: 'add-vendor-fin-details',
  EDIT_VENDOR_FINANCE_DETAILS: 'edit-vendor-fin-details',
  APPROVE_VENDOR_FINANCE: 'approve-vendor-fin',
  PAST_ADVANCE_TYPE_EFFECTIVE_DATE: 'past-advance-type-effective-date',
  ADD_EDIT_VENDOR_DETAILS: 'add-edit-vendor-details',
  DOWNLOAD_VENDOR: 'download-vendor',
  ADD_CUSTOMER: 'add-customer',
  EDIT_CUSTOMER: 'edit-customer',
  APPROVE_CUSTOMER_OPS: 'approve-customer-ops',
  ADD_CUSTOMER_FINANCE_DETAILS: 'add-customer-fin-details',
  EDIT_CUSTOMER_FINANCE_DETAILS: 'edit-customer-fin-details',
  APPROVE_CUSTOMER_FINANCE: 'approve-customer-fin',
  ADD_EDIT_CUSTOMER_DETAILS: 'add-edit-customer-details',
  DOWNLOAD_CUSTOMER: 'download-customer',
  ADD_VEHICLE: 'add-vehicle',

  DELETE_TRIP_COMMENTS: 'delete-trip-comments',

  ADD_ZAST_NOW_VEHICLE: 'add-zast-now-vehicle',
  EDIT_ZAST_NOW_VEHICLE: 'edit-zast-now-vehicle',
  ADD_CONTRACT_VEHICLE: 'add-contract-vehicle',
  EDIT_DO: 'edit-do',
  RELEASE_LOAD: 'falcon-release-load',
  DELETE_POD: 'pod-delete',
  VIEW_APPROVE_REJECT: 'action-payment-entity',
  EDIT_PAYMENT_ENTITY: 'edit-payment-entity',
  EDIT_BILLING_ENTITY: 'edit-billing-entity',
  TERMINATE_FALCON_TRIP: 'terminate-falcon-trip',
  EDIT_CONTRACT_VEHICLE: 'edit-contract-vehicle',
  EDIT_ZAST_DRIVER_PHONE: 'edit-zast-vehicle-driver-phone',
  EDIT_BLACKLISTED_VEHICLE: 'edit-blacklisted-vehicle',

  EDIT_VEHICLE_CATEGORY: 'edit-vehicle-category',
  EDIT_VEHICLE: 'edit-vehicle',
  // ADD_EDIT_VEHICLE_DETAILS: 'add-edit-vehicle-details',
  DOWNLOAD_VEHICLE: 'download-vehicle',
  // EDIT_VEHICLE_PHONE: 'edit-vehicle-phone',
  ADD_DRIVER: 'add-driver',
  EDIT_DRIVER: 'edit-driver',
  EDIT_DRIVER_PHONE: 'edit-driver-phone',
  // ADD_EDIT_DRIVER_DETAILS: 'add-edit-driver-details',
  DOWNLOAD_DRIVER: 'download-driver',
  UPLOAD_DOCUMENT: 'upload-document',
  // MODIFY_DOCUMENTS: 'modify-documents',
  BEGIN_TRIP: 'begin-trip',
  RESOLVED_ESCALATION: 'resolved-escalation',
  TERMINATE_TRIP: 'terminate-trip',
  HALT_TRIP: 'halt-trip',
  TOUCHING_IN: 'touching-in',
  TOUCHING_OUT: 'touching-out',
  REACHED_DESTINATION: 'reached-destination',
  DRY_RUN_REACHED_DESTINATION: 'dryrun-reached-destination',
  CLOSE_TRIP: 'close-trip',
  ADD_RUNNING_STATUS: 'add-running-status',
  ADD_TRIP_COMMENT: 'add-trip-comment',
  EDIT_TRIP_ROUTE: 'edit-trip-route',
  EDIT_TRIP_VEHICLE: 'edit-trip-vehicle',
  ADD_MANUAL_LOCATION: 'add-manual-location',
  DOWNLOAD_TRIP: 'download-trip',
  UPDATE_LR: 'update-lr',
  SAVE_VERIFY_LR: 'save-and-verify-lr',
  EDIT_VERIFY_LR: 'edit-verified-lr',
  DOWNLOAD_TRACKING: 'download-tracking',
  // VIEW_GPS_FEED: 'view-gps-feed',
  VIEW_VODAFONE_NUMBERS: 'view-vodafone-numbers',
  UPDATE_VODAFONE_NUMBERS: 'update-vodafone-numbers',
  POD_TRACK: 'pod-track',
  ASSIGN_POC_POD: 'assign-poc-pod',
  UPDATE_POD_STATUS: 'update-pod-status',
  UPLOAD_POD: 'upload-pod',
  BULK_UPLOAD_POD: 'bulk-upload-pod',
  UPDATE_VENDOR_BILL: 'update-vendor-bill',
  // CREATE_ADVANCE: 'create-advance',
  // VIEW_ADVANCE: 'view-advance',
  SEARCH_ADVANCE: 'search-advance',
  EDIT_TRIP_HISTORY: 'edit-trip-history',
  EDIT_HALT_HISTORY: 'edit-halt-history',
  DELETE_TRIP_HISTORY: 'delete-trip-history',
  DELETE_HALT_HISTORY: 'delete-halt-history',
  // VIEW_ADVANCE_REQUESTS: 'view-advance-requests',
  PROCESS_ADVANCE: 'process-advance',
  TRIP_CHARGES: 'trip-charges',
  REQUEST_TRIP_ADVANCE: 'request-trip-advance',

  REQUEST_PROCESS_ADVANCE: 'process-advance',

  DIRECT_PAYOUTS_REQUEST_PROCESS_ADVANCE: 'process-advance',

  RAZORPAY_PAYOUTS: 'razorpay-payouts',
  VIEW_HAPPAY_CARD_HISTORY: 'view-happay-card-history',
  DIRECT_PAYOUTS: 'direct-payouts',

  RAZORPAY_DIRECT_PAYOUTS: 'razorpay-direct-payouts',

  BILL_PAYMENT_MAPPING: 'bill-payment-mapping',
  BYPASS_CNT_DOCS: 'bypass-contract-add-edit-vehicle-doc',
  BYPASS_ZASTNOW_DOCS: 'bypass-zastnow-add-edit-vehicle-doc',
  AD_HOC_ADVANCE: 'create-adhoc-advance',
  SHOW_OR_HIDE_PENDING_TRIPS: 'process-trips-fnf',
  APPROVE_ADVANCE_REQUEST: 'approve-advance-request',
  VERIFY_BY_KAM_LOAD_PROCURE_USER: 'verify-by-kam-load-procure-user',
  VELOCITY_REQUEST_ADVANCE: 'velocity-request-advance',
  DELTA_REQUEST_ADVANCE: 'delta-request-advance',
  HORIZON_REQUEST_ADVANCE: 'horizon-request-advance',
  FALCON_REQUEST_ADVANCE: 'falcon-request-advance',
  CREATE_ADHOC_ADVANCE: 'create-adhoc-advance',
  VIEW_CUSTOMER_REPORT: 'view-customer-report',
  APPROVE_VENDOR_OPS: 'approve-vendor-ops',
  APPROVE_DRIVER: 'approve-driver',
  VIEW_DAILY_REPORT: 'view-daily-report',
  REVENUE_MASTER_RIGHTS: 'revenue-master-rights',
  UPDATE_TRIP_REVENUE: 'update-trip-revenue',
  GENERATE_ANNEXURE: 'generate-annexure',
  REJECT_ANNEXURE: 'reject_annexure',
  APPROVE_ANNEXURE: 'approve_annexure',
  SAVE_ANNEXURE_BILL_NO: 'save_annexure_bill_no',
  GENERATE_CREDIT_NOTE_ANNEXURE: 'generate-credit-note-annexure',
  REVENUE_EDIT_REFERENCE: 'revenue_edit_reference',
  VIEW_CUSTOMER_COST: 'view-customer-cost',
  UPDATE_CUSTOMER_COST: 'view-customer-cost',
  GENERATE_COST_ANNEXURE: 'generate-cost-annexure',
  APPROVE_COST_ANNEXURE: 'approve-cost-annexure',
  REJECT_COST_ANNEXURE: 'reject-cost-annexure',
  BILLABLE_NONBILLABLE_CONVERSION: 'billable-nonbillable-conversion',
  BOOKING_TYPE_CHANGE: 'booking-type-change',
  // EDIT_TRIP_VENDOR: 'edit-trip-vendor',
  // EDIT_CLOSED_TRIP_VENDOR: 'edit-closed-trip-vendor',
  VIEW_MIS_REPORT: 'view-mis-report',
  VIEW_HALTED_PAGE: 'view-halted-page',
  GEOFENCE_DELETE: 'geofence-delete',
  GEOFENCE_ADD: 'geofence-add',
  GEOFENCE_VIEW: 'geofence-view',
  VIEW_MISSING_REVENUE_SUMMARY: 'view-missing-revenue-summary',
  VIEW_TRIPS_MARKET_REVENUE: 'view-trips-market-revenue',
  ADD_MARKET_REVENUE: 'add-market-revenue',
  VIEW_DRIVER_TRACKING: 'view-driver-tracking',
  EDIT_TRIP_DRIVER: 'edit-trip-driver',
  VIEW_TRIP_GPS_DISTANCE: 'view-trip-gps-distance',
  DELETE_LR: 'delete-lr',
  VIEW_LR: 'view-lr',
  VIEW_VEHICLE_AVERAGE_RUN_REPORT: 'view-vehicle-average-run-report',
  CAN_VIEW_ATTENDANCE: 'can-view-attendance',
  CAN_MARK_ATTENDANCE: 'can-mark-attendance',
  CAN_APPROVE_ATTENDANCE: 'can-approve-attendance',
  DOWNLOAD_REPORT: 'download-report',
  APPROVE_FNF_1: 'fnf-approver-1',
  APPROVE_FNF_2: 'fnf-approver-2',
  REQUEST_FNF: 'request-fnf',
  VIEW_ZAST_NOW_REPORT: 'view-zast-now-report',
  VIEW_PAYMENT_REPORT: 'view-payment-report',
  CAN_UPLOAD_FASTTAG: 'can-upload-fasttag',
  CAN_APPROVE_FASTTAG: 'can-approve-fasttag',
  CAN_UPLOAD_DIESEL: 'can-upload-diesel',
  CAN_APPROVE_DIESEL: 'can-approve-diesel',
  CAN_VIEW_REPAIR_MAINTENANCE: 'can-view-repair-maintenance',
  CAN_VIEW_HAPPAY_PROCESSING_REQUEST: 'can-view-happay-processing-request',
  CAN_EDIT_REPAIR_MAINTENANCE: 'can-edit-repair-maintenance',
  VIEW_TRIP_PERFORMANCE: 'view-trip-performance',
  VERIFY_DRYRUNS: 'verify-dryruns',
  ADD_ESCALATION: 'add-escalation',
  VIEW_ESCALATION: 'view-escalation',
  //rate-matrix user rights
  VIEW_RATE_MATRIX_MASTER: 'view-rate-matrix-master',
  UPLOAD_RATE_MATRIX_MASTER: 'upload-rate-matrix-master',
  VIEW_RATE_MATRIX_RETURN_RATE: 'view-rate-matrix-return-rate',
  UPLOAD_RATE_MATRIX_RETURN_RATE: 'upload-rate-matrix-return-rate',
  VIEW_RATE_MATRIX_FORWARD_RATE: 'view-rate-matrix-forward-rate',
  VIEW_BOOKING_RATE: 'view-booking-rate',
  VIEW_CUSTOMER_OPS_DETAILS: 'view-customer-ops-details',
  VIEW_CUSTOMER_FIN_DETAILS: 'view-customer-fin-details',
  //bulk upload rights
  VIEW_BULK_UPLOAD: 'view-bulk-upload',
  VIEW_LATEST_UPLOAD: 'view-latest-upload',
  CREATE_BID: 'create-bid',
  UPDATE_BID: 'update-bid',
  OTHER_PAYMENT_UPLOAD: 'other-payment-upload',
  DELETE_VEHICLE_DOCUMENT: 'delete-vehicle-document',
  E_WAY_BILL_VERIFICATION: 'e-way-bill-verification',
  VIEW_VEHICLE_REPORT: 'view-vehicle-report',
  VIEW_VEHICLE_SUMMARY_REPORT: 'view-vehicle-summary-report',
};

export const TRIP_STATUS_TABLE = {
  CREATED: 'CR',
  IN_TRANSIT: 'IN',
  TOUCHING: 'AI',
  ARRIVED: 'AR',
  CLOSED: 'CL',
  HALTED: 'HL',
  TERMINATED: 'TM',
  CROSS_DOCKED: 'CD',
};

export const TRIP_STATUS_TEXT = {
  [TRIP_STATUS_TABLE.CREATED]: 'Created',
  [TRIP_STATUS_TABLE.IN_TRANSIT]: 'In Transit',
  [TRIP_STATUS_TABLE.TOUCHING]: 'In Transit',
  [TRIP_STATUS_TABLE.ARRIVED]: 'Arrived Destination',
  [TRIP_STATUS_TABLE.CLOSED]: 'Closed',
  [TRIP_STATUS_TABLE.HALTED]: 'In Transit',
  [TRIP_STATUS_TABLE.TERMINATED]: 'Terminated',
  [TRIP_STATUS_TABLE.CROSS_DOCKED]: 'Cross-Docked',
};

export const STATUSES = {
  HL: 'TRIP HALTED',
  IN: 'TRIP IN TRANSIT',
  AR: 'ARRIVED DESTINATION',
  TM: 'TRIP TERMINATED',
  CL: 'TRIP CLOSED',
};

export const UPLOAD_URLS = {
  VEHICLE_DOCUMENTS: '/vehicle/upload-document',
};

export const MASTER_RECORD_DOWNLOAD_URL = {
  VENDOR: 'vendor/download',
  VENDOR_FINANCE: 'vendor/vendor-financial-data',
  CUSTOMER: 'customer/download',
  CUSTOMER_FINANCE: 'customer/customer-financial-data',
  DRIVER_OTHER: 'vendor/other-drivers-report',
  DRIVER_ZAST: 'vendor/zast-drivers-report',
  VEHCILE: 'vehicle/report/all',
  CITY: 'booking/report-cities',
  CONSIGNOR: 'booking/download-consignors',
  ROUTES: 'booking/report-route-tat',
  DISTANCE_MAP: 'booking/download-route-distance',
  USERS: 'internal/user/download',
};

export const RATE_MATRIX_DOWNLOAD_URL = {
  ROUTE_MARGIN: 'booking/routemargin?download=true',
  COST: 'booking/cost?download=true',
  RETURN_ROUTE: 'booking/returnrate?download=true',
  FORWARD_ROUTE: 'booking/forwardrouterate?download=true',
};

export const TRACKING_DOWNLOAD = {
  RECENT: 'tracking/download-tracking-report/recent',
  ALL_VEHICLES: 'tracking/download-tracking-report/all',
  DRIVER_TRACKING: 'driver/download',
};

export const REVENUE_DOWNLOAD = {
  BASE_RATE: 'finance/revenue-base-rate/download/',
};

export const APPROVAL_ACTIONS = {
  APPROVE: 'approve',
  REJECT: 'reject',
};

export const APPROVAL_STATUS_FINANCIAL = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  NO_DETAILS: 'No Details',
  BLOCKED: 'Blocked',
};

export const FINANCIAL_REJECTION_REASONS = {
  CUSTOMER_INCORRECT_DATA: 'Incorrect Customer Data',
  VENDOR_INCORRECT_DATA: 'Incorrect Vendor Data',
  INCORRECT_ACCOUNT: 'Incorrect Account Number',
  INCORRECT_IFSC: 'Incorrect IFSC Code',
  INCORRECT_PAN: 'Incorrect PAN Number',
  PAN_NOT_CLEAR: 'PAN Card Image not clear',
  CHEQUE_NOT_CLEAR: 'Cancelled Cheque Image not clear',
  BUSINESS_CARD_INVALID: 'Business card not valid',
  VENDOR_TDS_INVALID: 'TDS File not valid',
};

export const MONTHS = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const CALENDAR_FORMAT = 'DD-MM-YYYY';

export const ATTENDANCE = {
  P: 'P',
  A: 'A',
  I: 'I',
};

export const PERFORMANCE_TABLE = {
  OVERALL: 'OVERALL',
  ADHOC: 'ADHOC',
  SCHEDULED: 'SCHEDULED',
};

export const LOCK_STATUS = {
  LOCKED: 'locked',
  UNLOCKED: 'unlocked',
  UNKNOWN: 'unknown',
};

export const BIDDING = {
  OPEN: 'open_for_load_board',
  IN_PROGRESS: 'bidding_in_progress',
  CLOSED: 'closed_bidding',
};
